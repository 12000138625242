import React from 'react'
import './contact.css'
import { MdOutlineEmail } from 'react-icons/md'
import { FaLinkedin } from "react-icons/fa";
import { BsWhatsapp } from 'react-icons/bs'
import {useRef} from 'react';
import emailjs from 'emailjs-com'


const Contact = () => {;

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_goxdrfn', 'template_l8zg4lo', form.current, 'FgQNumJ8X4i7WW2LL')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

      e.target.reset()
  };
  return (
    <section id='contact'>
      <h5> Get in Touch</h5>
      <h2> Contact Me</h2>

      <div className='container contact__container'>
        <div className='contact__options'>
          <article className='contact__option'>
            <MdOutlineEmail className='contact__option-icon'/>
            <h4>Email</h4>
            <h5>hsheaib@outlook.de</h5>
            <a href="mailto:hsheaib@outlook.de" target='_blank'>Send an Email!</a>
          </article>

          <article className='contact__option'>
            <FaLinkedin className='contact__option-icon'/>
            <h4>Linkedin</h4>
            <h5>Hussein Sheaib</h5>
            <a href="https://www.linkedin.com/in/hsheaib/" target='_blank'>Connect on Linkedin!</a>
          </article>

          <article className='contact__option'>
            <BsWhatsapp className='contact__option-icon'/>
            <h4>Whatsapp</h4>
            <h5>004915127982067</h5>
            <a href="https://wa.me/4915127982067" target='_blank'>Send a Message!</a>
          </article>
        </div>

      <form ref={form} onSubmit={sendEmail}>
    	  <input type='text' name='name' placeholder='Your Full Name' required />
        <input type="email" name="email" placeholder="Your Email" required />
        <textarea name='message' rows="7" placeholder="Your Message" required ></textarea>
        <button type='submit' className ='btn btn-primary'>Send</button>

      </form>

      </div>
    </section>
  )
}

export default Contact