import React from 'react'
import './experience.css'
import { BsPatchCheckFill } from 'react-icons/bs'
import { MdStar } from "react-icons/md";

const Experience = () => {
  return (
    <section id='experience'>
      <h5> Skills </h5>
      <h2> My Experience</h2>

      <div className='container experience__container'>
        <div className='experience wd'>
          <h3> Web Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>HTML</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>CSS</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Javascript</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Typescript</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
              </article>
              <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>React</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
           
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Django</h4>
              <small className='text-light'><MdStar /> <MdStar /> </small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Flask</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
            </article>
          </div>
        </div>
        {/* End of WD */}
        <div className='experience wd'>
          <h3> Programming and Development</h3>
          <div className="experience__content">
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Python</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
            
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Java</h4>
              <small className='text-light'> <MdStar /> <MdStar /></small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>C#/C++</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
            
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
            <div>
            <h4>Arduino</h4>
            <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              
            </div>
            </article>
              <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Rust</h4>
              <small className='text-light'> <MdStar /> <MdStar /></small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>ElectronJS</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
            </article>
            <article className='experience__details'>
              <BsPatchCheckFill className='experience__details-icon' />
              <div>
              <h4>Tauri</h4>
              <small className='text-light'><MdStar /> <MdStar /> <MdStar /></small>
              </div>
            </article>
          </div>
        </div>
      {/* End of Programming and DEvelopment */}
      </div>
    </section>
  )
}

export default Experience