import React from 'react'
import './nav.css'
import {AiOutlineHome, AiOutlineInfo} from 'react-icons/ai'
import {TbTimeline} from 'react-icons/tb'
import {MdOutlineMiscellaneousServices} from 'react-icons/md'
import {FiSend} from 'react-icons/fi'
import { useState } from 'react'

const Nav = () => {
  const [activeNav, setActiveNav] = useState('#')
  return (
    <nav>
      <a href='#' onClick={() => setActiveNav('#')} className={activeNav === '#' ? 'active': ''}><AiOutlineHome /></a>
      <a href='#about' onClick={() => setActiveNav('#about')} className = {activeNav === '#about' ? 'active': ''}><AiOutlineInfo /></a>
      <a href='#experience' onClick={() => setActiveNav('#experience')} className = {activeNav === '#experience' ? 'active': ''}><TbTimeline /></a>
      <a href='#portfolio' onClick={() => setActiveNav('#portfolio')} className = {activeNav === '#portfolio' ? 'active': ''}><MdOutlineMiscellaneousServices /></a>
  	  <a href='#contact' onClick={() => setActiveNav('#contact')} className = {activeNav === '#contact' ? 'active': ''}><FiSend /></a>
      
      </nav>
  )
}

export default Nav