import React from 'react'
import './about.css'
import IMG from '../../assets/img2.jpg'
import { FaAward } from 'react-icons/fa'
import { VscFolderLibrary } from 'react-icons/vsc'

const About = () => {
  return (
    <section id='about'>
      <h5> Get To Know</h5>
      <h2> About Me</h2>

      <div className='container about__container'>
        <div className='about__me'>
          <div className='about__me-image'>
            <img src={IMG} alt='About Image' />
          </div>
        </div>
        
        <div className='about__content'>

          <div className='about__cards'>
            <article className='about__card'>
              <FaAward className='about__icon' />
              <h5>Experience</h5>
              <small>2 years working as student assistant</small>
            </article>
            <article className='about__card'>
              <VscFolderLibrary className='about__icon' />
              <h5>Projects</h5>
              <small>10+ completed projects</small>
            </article>
          </div>
          

          <p>
          I'm an embedded systems engineer freshly graduated from my master's program, with hands-on experience gained over two dynamic years as a working student in a startup environment. In this role, I held multiple responsibilities, from programming and tool development initiatives to overseeing the IT infrastructure and building the company's website. Additionally, I managed ad platforms and integrated their pixels/tags with our website, contributing significantly to our digital footprint.
          </p>
          <p>
          This multifaceted responsibility not only accelerated my professional growth but also improved me dependability and reliability wise, where precision was paramount. The fast-paced environment demanded continual skill expansion, prompting me to broaden my expertise across diverse domains.
          </p>

          <a href='#contact' className='btn btn-primary'> Contact me</a>
        </div>
      </div>
    </section>
  )
}


export default About