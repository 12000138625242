import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/Setup.png'
import IMG2 from '../../assets/kalku.png'
import Collapsible from './Collapsible'

const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5> My Recent Work</h5>
      <h2>Portfolio</h2>

      <div className='container portfolio__container'>
        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG1} alt="" />
          </div>
          <h3> Observation System </h3>
          <Collapsible label='More Info'>
            <h2> Observation System </h2>
            <p>
              Development of an observation system that detects hand/body gestures and updates the industrial robot with the appropriate movement. First, the user approaches the camera to choose a specific pattern that the robot should sort its parts. The camera then starts scanning the area, with computer vision and image processing features it is possible to locate the objects of interest. Followingly, the robot is updated with the path plans needed to collect the objects. In the case a human approaches the workspace, the robot pauses until the user leaves again.
            </p>
          </Collapsible>
          
        </article>

        <article className='portfolio__item'>
          <div className='portfolio__item-image'>
            <img src={IMG2} alt="" />
          </div>
          <h3> Calculation Tool</h3>
          <Collapsible label='More Info'>
          <h2>
            Summmary
          </h2>
          <p>
            In the construction business, many companies are interested in participating in public bids and potentially winning them. What stops small to medium businesses from participating is that every bid needs calculation and many of these companies do not have the experience or the time to deal with this task. Therefore, a solution was possible by referring to a company that simplifies the calculation process with this tool that provides means for calculation, collaboration between different calculators, import and export of spreadsheets, as well as maintaining and saving saved and exported formulas.
          </p>
          </Collapsible>
        </article>
      </div>
    </section>
  )
}

export default Portfolio