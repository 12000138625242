import React from 'react'
import './collapsible.css'
import { useState, useRef } from 'react'


const Collapsible = (props) => {
    const [showText, isShowText] = useState(false)
    const contentRef = useRef();
    const onClick = () => isShowText(!showText);
  return (
    <div>
    <button type='button' onClick={onClick}>
    {props.label}
  </button>
  <div className='content-parent' ref = {contentRef} style={showText ? {height: contentRef.current.scrollHeight + 'px'}: {height:'0px'}}>
    <div className='content'>{props.children}</div>
  </div>


    </div>
  )
}

export default Collapsible